@import "../../../SCSS/Mixins.scss";

.popupDialog {
  width: 100%;
  &.smallView {
    .MuiPaper-root {
      width: 400px;
      border-radius: 24px;
    }
    .closeButton {
      background-color: $white;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      border-bottom: 1px solid $primary;
      padding: 8px 12px;
    }
  }
  .MuiPaper-root {
    border-radius: 0px;
    margin: 0px;
    max-width: 100% !important;
    width: 100%;
  }
  .closeButton {
    background-color: $white;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    border-bottom: 1px solid $primary;
    padding: 8px 24px;
    @media only screen and (max-width: 768px) {
      padding: 8px 8px;
    }
  }
}
